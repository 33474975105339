<template>
  <div>
    <modal-wrapper ref="modalWrapper" v-if="needConfirmation">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('forms.verifyPhone.title')}}</h5>
            <button @click="closeModal(signInError ? 3600 : 0)" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div :class="{'d-none': showVerificationCodeField || signInError || confirmed}">
              <span>{{
                  $tc('forms.verifyPhone.ask', requests.count, {
                    phone: phoneNumber,
                    messages: requests.count,
                    term: $t('forms.buyPackages.terms.' + requests.term),
                  })
                }}</span>
              <div class="mt-3">
                <button :disabled="disableFirstButtons" class="btn btn-primary" id="send-verification-code" ref="sendCodeRef">
                  <div class="spinner-border spinner-border-sm mr-1" v-if="disableFirstButtons" />
                  {{$t('forms.verifyPhone.send')}}
                </button>
                <button :disabled="disableFirstButtons" class="btn btn-danger ml-4" @click="cancel">{{$t('cancel')}}</button>
              </div>
            </div>
            <div v-if="showVerificationCodeField">
              <Form @submit="submit" v-slot="{ meta }" ref="form" v-if="!signInError">
                <div class="form-group">
                  <label for="verificationCode">{{ $t('forms.verifyPhone.code') }}</label>
                  <Field
                    as="input"
                    name="verificationCode"
                    id="verificationCode"
                    rules="required"
                    class="form-control mb-0"
                    :disabled="isDisabled"
                  ></Field>
                  <ErrorMessage
                    as="div"
                    name="verificationCode"
                    class="error-message"
                  />
                </div>
                <div class="mt-4">
                  <button class="btn btn-success shadow-2 mr-4" :disabled="isDisabled || (!meta.valid && meta.dirty)">
                    <div class="spinner-border spinner-border-sm mr-1" v-if="isDisabled === true" />
                    {{$t('forms.verifyPhone.verify')}}
                  </button>
                  <button class="btn btn-secondary shadow-2" @click.prevent="resend" :disabled="resendDisabled || isDisabled">
                    {{$t('forms.verifyPhone.resend')}}
                    <template v-if="canResendAfter > 0">
                      ({{canResendAfter}} {{$t('units.second.short')}})
                    </template>
                  </button>
                </div>
              </Form>
            </div>
            <div v-if="signInError">
                {{$t('error')}}: {{$t('forms.verifyPhone.errors.later')}}
            </div>
            <div v-if="confirmed">
              <div v-if="!serverVerificationError">{{$t('forms.verifyPhone.success')}}</div>
              <div v-else>{{$t('forms.verifyPhone.errors.server')}}</div>
            </div>
          </div>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>

import { watch, ref, defineAsyncComponent } from 'vue'
import { usePhoneVerification } from '@/application/composables/phoneVerification'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  name: 'PhoneVerify',
  components: {
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const {
      submit,
      disableFirstButtons,
      resendDisabled,
      resend,
      showVerificationCodeField,
      initCaptcha,
      isDisabled,
      canResendAfter,
      confirmed,
      wrongCodeError,
      closeModal,
      form,
      modalWrapper,
      signInError,
      modalWasClosed,
      serverVerificationError,
      needConfirmation,
      phoneNumber,
      requestsTerm,
      openFromHome,
    } = usePhoneVerification()
    const sendCodeRef = ref(null)

    // if need confirmation and modal rendered
    watch(() => needConfirmation.value && modalWrapper.value, (value) => {
      if (value && !modalWasClosed.value) {
        modalWrapper.value.open()
      }
    })

    watch(() => openFromHome.value, (value) => {
      if (value) {
        modalWrapper.value.open()
      }
    })

    watch(() => !!sendCodeRef.value && needConfirmation, (value) => {
      if (value) {
        initCaptcha()
      }
    })

    return {
      showVerificationCodeField,
      modalWrapper,
      cancel: () => {
        closeModal(3600 * 24 * 7)
      },
      resend,
      submit,
      resendDisabled,
      sendCodeRef,
      isDisabled,
      canResendAfter,
      confirmed,
      wrongCodeError,
      form,
      signInError,
      closeModal,
      serverVerificationError,
      needConfirmation,
      phoneNumber,
      requests: requestsTerm,
      disableFirstButtons,
    }
  },
}
</script>
